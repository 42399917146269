import React, { useState } from "react";
import { graphql, navigate, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import moment from "moment";
import { PrismicRichText } from "@prismicio/react";
import ContactForm from "../components/common/ContactForm";
import ArrowForward from "../images/ArrowForward";
import ArrowUp from "../images/ArrowUp";
import Layout from "../components/common/Layout";
import "../styles/pages/homepage.scss";
import SearchComponent from "../components/blocks/Search";
import Seo from "../components/meta/Seo";

const HomePage = ({ data }) => {
  const { page, services } = data;

  const autoplayOptions = {
    delay: 4000,
    rootNode: emblaRoot => emblaRoot.parentElement,
  };

  const [emblaRef] = useEmblaCarousel({ loop: false, align: "start" }, [
    Autoplay(autoplayOptions),
  ]);
  const [emblaRef2] = useEmblaCarousel({ loop: false, align: "start" }, [
    Autoplay(autoplayOptions),
  ]);

  const [serviceImageGroup1, setServiceImageGroup1] = useState(
    page.data.body[0].items[0].service_image.gatsbyImageData
  );
  const hoverImageHandlerGroup1 = index => {
    setServiceImageGroup1(
      page.data.body[0].items[index].service_image.gatsbyImageData
    );
  };

  const [serviceImageGroup2, setServiceImageGroup2] = useState(
    page.data.body[1].items[0].service_image.gatsbyImageData
  );
  const hoverImageHandlerGroup2 = index => {
    setServiceImageGroup2(
      page.data.body[1].items[index].service_image.gatsbyImageData
    );
  };

  const [serviceImageGroup3, setServiceImageGroup3] = useState(
    page.data.body[2].items[0].service_image.gatsbyImageData
  );
  const hoverImageHandlerGroup3 = index => {
    setServiceImageGroup3(
      page.data.body[2].items[index].service_image.gatsbyImageData
    );
  };

  return (
    <div className="homepage">
      <Seo page={page} />
      <Layout>
        <div className="homepage-bg" />
        <div className="px-4 hero-container max-width">
          <GatsbyImage
            className="hero-desktop"
            image={page.data.hero_image.gatsbyImageData}
            alt="hero image"
          />
          <GatsbyImage
            className="hero-mobile"
            image={page.data.hero_image_mobile.gatsbyImageData}
            alt="hero image"
          />
          <h1>{page.data.hero_title.text}</h1>
        </div>
        <div className="about-homepage-container px-5 max-width">
          <div className="about-homepage-text p-5">
            <PrismicRichText field={page.data.about_title.richText} />
            <PrismicRichText field={page.data.about_text.richText} />
            <Link className="about-link" to={page.data.about_page.url}>
              ΠΕΡΙΣΣΟΤΕΡΑ
            </Link>
          </div>
          <Link to={page.data.about_page.url}>
            <GatsbyImage
              image={page.data.about_image.gatsbyImageData}
              alt="about image"
            />
          </Link>
        </div>
        <div className="px-4 logos max-width">
          <div className="row">
            {page.data.member_logos.map(logo => (
              <figure className="figure col-md" key={logo.logo_title.text}>
                <GatsbyImage
                  image={logo.member_logo.gatsbyImageData}
                  objectFit="contain"
                  alt="logo"
                />
                <figcaption className="figure-caption">
                  {logo.logo_title.text}
                </figcaption>
              </figure>
            ))}
          </div>
        </div>

        <div className="top-services max-width px-4">
          <h2>{page.data.top_services.richText[0].text}</h2>
          {page.data.body.map((serviceGroup, groupIndex) => (
            <div
              key={`${serviceGroup?.id}-${groupIndex}`}
              className="row"
              style={{ marginTop: "35px" }}
            >
              <GatsbyImage
                className="col service-image"
                style={{ maxWidth: "500px" }}
                image={
                  groupIndex === 0
                    ? serviceImageGroup1
                    : groupIndex === 1
                    ? serviceImageGroup2
                    : serviceImageGroup3
                }
                alt="service image"
              />
              <div className="buttons col">
                {serviceGroup.items.map((service, serviceIndex) => (
                  <Link
                    key={service.service_title.text}
                    className="service-link"
                    onMouseEnter={() => {
                      if (groupIndex === 0) {
                        hoverImageHandlerGroup1(serviceIndex);
                      } else if (groupIndex === 1) {
                        hoverImageHandlerGroup2(serviceIndex);
                      } else if (groupIndex === 2) {
                        hoverImageHandlerGroup3(serviceIndex);
                      }
                    }}
                    to={service.service_link.url}
                    key={service.service_title.text}
                  >
                    {service.service_title.text}
                    <ArrowForward />
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="max-width all-case-studies-slider">
          <div className="embla mx-4" ref={emblaRef}>
            <div className="embla__container">
              {data.allPrismicCaseStudies.nodes.map(caseStudy => {
                if (caseStudy.data.main_image.gatsbyImageData) {
                  return (
                    <Link
                      className="embla__slide"
                      to={caseStudy.url}
                      key={caseStudy.data.case_study_title.text}
                    >
                      <GatsbyImage
                        style={{ height: 367, borderRadius: 8 }}
                        image={caseStudy.data.main_image.gatsbyImageData}
                        alt=""
                      />
                    </Link>
                  );
                }
                return null;
              })}
              <Link
                className="embla__slide last all-case-studies-container"
                to={page.data.all_case_studies_link.url}
                key={page.data.all_case_studies_link.url}
              >
                <button
                  title="all case studies"
                  type="button"
                  className="all-case-studies"
                >
                  Όλα
                  <ArrowUp />
                </button>
              </Link>
            </div>
            <div>
              <Link
                className="all-case-studies"
                to={page.data.all_case_studies_link.url}
              >
                {page.data.all_case_studies_title.text}
                <ArrowUp />
              </Link>
            </div>
          </div>
        </div>
        <div className="max-width mx-4">
          <div className="embla mx-4  posts" ref={emblaRef2}>
            <div className="embla__container second-container posts">
              {data.allPrismicPosts.nodes.map((post, index) => (
                <Link
                  key={post.url}
                  type="button"
                  className="post-link"
                  to={post.url}
                >
                  <div className="embla__slide post">
                    {post.data.post_image.gatsbyImageData && (
                      <GatsbyImage
                        style={{ height: 539, borderRadius: 8 }}
                        image={post.data.post_image.gatsbyImageData}
                        alt=""
                      />
                    )}
                    <div className="post-content">
                      <ArrowUp />
                      <p className="post-date">
                        {moment(post.data.post_date).format("MMMM Do YYYY")}
                      </p>
                      <h3 className="post-title">
                        {post.data.post_title.text}
                      </h3>
                      <p className="post-excert">
                        {post.data.post_content.text.substring(0, 150)}...
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="mx-4 max-width all-posts-container">
          <Link className="all-posts mx-4" to={page.data.all_posts_link.slug}>
            {page.data.all_posts_title.text}
            <ArrowUp />
          </Link>
        </div>
        <div className="max-width contact-form-container">
          <ContactForm className="contact-form mx-4" />
        </div>
      </Layout>
    </div>
  );
};

export default HomePage;

export const query = graphql`
  query homepageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicHomepage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url

      data {
        meta_title
        meta_description
        about_image {
          alt
          gatsbyImageData
        }
        about_text {
          richText
        }
        about_title {
          text
        }
        about_page {
          url
        }
        all_case_studies_link {
          slug
          url
        }
        all_case_studies_title {
          text
        }
        all_posts_link {
          slug
          url
        }
        all_posts_title {
          text
        }
        all_services_link {
          slug
        }
        all_services_title {
          text
        }
        top_services {
          text
        }
        hero_image {
          alt
          gatsbyImageData
        }
        hero_image_mobile {
          alt
          gatsbyImageData
        }
        hero_title {
          text
        }
        member_logos {
          logo_title {
            text
          }
          member_logo {
            gatsbyImageData
            alt
          }
        }
        body {
          ... on PrismicHomepageDataBodyServices {
            id
            items {
              service_image {
                alt
                gatsbyImageData
              }
              service_link {
                url
              }
              service_title {
                text
              }
            }
            slice_label
          }
        }
        top_services {
          richText
        }
      }
    }
    services: prismicServices(lang: { eq: $lang }) {
      data {
        hero_image {
          gatsbyImageData
        }
      }
    }
    allPrismicPosts {
      nodes {
        id
        data {
          post_image {
            gatsbyImageData
            alt
          }
          post_title {
            text
          }
          post_content {
            richText
            text
          }
        }
        url
      }
    }
    allPrismicServices {
      nodes {
        id
        url
        data {
          service_title {
            text
          }
        }
      }
    }
    allPrismicCaseStudies {
      nodes {
        id
        url
        data {
          body {
            ... on PrismicCaseStudiesDataBodyBeforeAndAfter {
              id
              primary {
                content {
                  richText
                }
                image_after {
                  alt
                  gatsbyImageData
                }
                image_before {
                  alt
                  gatsbyImageData
                }
              }
            }
            ... on PrismicCaseStudiesDataBodyPhotosList {
              id
              primary {
                content {
                  richText
                }
              }
              items {
                image {
                  alt
                  gatsbyImageData
                }
              }
            }
          }
          main_image {
            gatsbyImageData
            alt
          }
          case_study_title {
            richText
            text
          }
        }
      }
    }
  }
`;
