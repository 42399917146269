import React from "react";

const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
    >
      <path
        d="M3.52148 1H21.5345C23.7436 1 25.5345 2.79086 25.5345 5V23.013"
        stroke="#4E4645"
        strokeWidth="1.5"
      />
      <path
        d="M24.9622 1.69995L0.662109 26"
        stroke="#4E4645"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ArrowUp;
